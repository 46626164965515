import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function pluralRules(qty, choicesLength, locale){
  if(qty){
    const choices = {
      zero:   0,
      one:    1,
      two:    2,
      few:    3,
      many:   4,
      other:  5
    };
    return Math.min(choices[new Intl.PluralRules(locale).select(qty)], choicesLength - 1);
  } else {
    return 0;
  }
}

export default createI18n({
  legacy: false,
  locale: 'uk',
  fallbackLocale: 'uk',
  pluralRules: {
    uk: function(qty, choicesLength){
      return pluralRules(qty, choicesLength, 'uk');
    },
    en: function(qty, choicesLength){
      return pluralRules(qty, choicesLength, 'en');
    },
    kr: function(qty, choicesLength){
      return pluralRules(qty, choicesLength, 'kr');
    }    
  },  
  messages: loadLocaleMessages()
})
