export default {
  "message": {
    "navbar": {
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인터페이스 언어"])},
      "queues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 대기열"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로젝트 정보"])}
    },
    "main": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경고"])},
      "eQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 대기열"])},
      "scanQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열에 합류하려면 시설 입구에서 QR 코드를 스캔하세요"])},
      "connectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열 참여"])},
      "completeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 시작"])},
      "dropQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["큐 삭제"])},
      "dropQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열에서 나가려면 대기열에서 자신의 자리를 비워주세요."])},
      "skipQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열 건너뛰기"])},
      "skipQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장소에 도착할 시간이 없다면 앞에 고객 한 명을 건너뛰세요."])},
      "selectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 대기열 선택"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이해했습니다"])},
      "youNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신은 다음 고객입니다"])},
      "nowQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신의 대기열"])},
      "nowQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 고객이 서비스를 받는 동안 좌석으로 이동해 주세요."])},
      "yourPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 서비스 번호"])},
      "beforeYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신보다 먼저 대기열에 있습니다"])},
      "noBeforeYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신 앞에 줄을 서 있는 사람이 없습니다."])},
      "afterYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신 이후"])},
      "noAfterYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신 뒤에 줄을 서 있는 사람이 없습니다."])},
      "averageService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 서비스 시간"])},
      "averageWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대략적인 대기 시간"])},
      "forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 시작 예측"])},
      "currentService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 클라이언트가 서비스 중입니다"])},
      "statInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 고객의 서비스 데이터를 기반으로 대략적으로 계산되었습니다."])},
      "userdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["양식을 작성하세요"])},
      "userdataInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열에 있는 동안 양식을 작성해 주세요. 서비스 속도가 빨라질 것입니다."])},
      "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유용한 정보"])},
      "userInfoInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열에 있는 동안 유용한 정보를 읽어보세요."])},
      "userPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제"])},
      "userPayInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["줄을 서 있는 동안 온라인으로 주문 금액을 결제해 주세요. 이렇게 하면 서비스 속도가 빨라집니다."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 애플리케이션 데이터를 삭제합니다"])},
      "worktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘 근무 시간"])},
      "timebreak": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["휴식 없음"]), _normalize(["휴식"]), _normalize(["휴식"])])},
      "booktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기 허용"])}
    },
    "dialogs": {
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
      "okQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의해 주셔서 감사합니다!"])},
      "drpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열에서 나갔습니다."])},
      "skpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기열을 포기하셨습니다. 감사합니다."])},
      "cnctQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의해 주셔서 감사합니다! 현재 대기열 상태를 모니터링하고 제 시간에 자리에 도착할 수 있는 시간을 계산해 보세요."])},
      "cnctQueueHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중요 정보"])},
      "cnctQueueSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대기열을 사용하면 시간에 맞춰 도착하지 못할 경우 뒤에 있는 사람에게 자리를 양보할 수 있습니다."])},
      "cnctQueuePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대기열을 사용하면 선불 결제를 할 수 있습니다."])},
      "cnctQueueDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계획이 변경되면 해당 버튼을 클릭하여 대기열에서 나가세요."])},
      "cnctQueuePush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["앞에 있는 고객이 서비스를 시작하면 알림을 받게 됩니다. 이를 위해서는 브라우저뿐만 아니라 앱이나 웹 페이지에 푸시 알림을 받을 수 있는 권한이 필요합니다. 우리는 이 기능을 다음에 대해 사용하지 않을 것을 약속합니다. 이 대기열에 있는 것과 관련되지 않은 모든 것."])},
      "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결 오류가 발생했습니다. 나중에 다시 시도해 주세요."])},
      "impossibleToSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회전을 포기하는 것은 불가능합니다."])},
      "impossibleIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금은 이 대기열에 참가할 수 없습니다."])},
      "queueNoExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 대기열이 존재하지 않습니다"])},
      "notInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신은 이 대기열에 없습니다."])},
      "alreadyComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 대기열에서 나갔습니다."])},
      "notYourQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이제 귀하의 대기열이 아닙니다."])},
      "dropConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 대기열에서 나갈 것인지 확인해주세요."])},
      "wipeConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 데이터 삭제 의사를 확인해 주세요."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터 삭제"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["푸시 알림"])},
      "pushInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["애플리케이션이 제대로 작동하려면 푸시 알림 수신을 허용해야 합니다. 푸시 알림이 금지된 경우 대기열 상태를 직접 모니터링해야 합니다."])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재부팅"])},
      "firebase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["푸시 알림 서비스를 등록하는 중입니다. 잠시 기다려 주세요."])}
    }
  }
}