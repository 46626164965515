export const QU_STATEMENT = {
  service: 0,
  free: 1,
  busy: 2,
  closed: 3
};
export const API_ERROR = {
  none: 0,
  noQueue: 100,
  request: 101,
  queue: 102,
  point: 103,
  pointCreate: 104,
  pointComplete: 105,
  pointSkip: 106,
  complete: 107
};
export const DEF = {
  debug: false,
  homeUrl: 'https://www.q.lafuente.ua/',
  logoUrl: 'https://www.q.lafuente.ua/img/logo-180.png',
  apiUrl: 'https://www.firebase.kharkovhost.com/get/api',
  wsUrl: 'wss://ws.tripcount.click:3000',
  wsTimeout: 5000,
  debugRebootTimeout: 10000,
  welcomeTimeout: 500,
  reactiveTimerInterval: 2000,
  overlayTimeout: 1000,
  apiVersion: 1,
  token: '',
  tokenLength: 6,
  dialogs: {
    show: false,
    theme: null,
    title: null,    
    actionTitle: null,    
    action: null,
    close: null,
    closeTitle: null,
    textarea: null,
    data: {}
  },  
  config: {
    locale: 'uk',
    push: false,
    queues: {}
  },
  queue: {
    token: null,
    title: ''
  },
  qu: {
    id: null,
    title: '',
    token: '',
    statement: QU_STATEMENT.busy,
    shedule: {},
    point: {
      id: null,
      idx: null,
      token: '',
      data: false
    },
    config: {
    },
    statistic: {
    }
  },
  langs: [
    {
      title: 'Українська',
      name: 'uk',
      valid: true
    },{
      title: 'English',
      name: 'en',
      valid: false
    },{
      title: 'Deutsch',
      name: 'de',
      valid: false
    },{
      title: 'Español',
      name: 'es',
      valid: false
    },{
      title: 'Français',
      name: 'fr',
      valid: false
    },{
      title: '日本語',
      name: 'jp',
      valid: false
    },{
      title: '한국인',
      name: 'kr',
      valid: true
    },{
      title: 'සිංහල',
      name: 'sg',
      valid: false
    },{
      title: '中国人',
      name: 'cn',
      valid: false
    }
  ],
  firebaseConfig: {
    apiKey: "AIzaSyBSMwCrQqoctf74CVgctJIbIXY2wc242rI",
    authDomain: "q-queue-ua.firebaseapp.com",
    projectId: "q-queue-ua",
    storageBucket: "q-queue-ua.appspot.com",
    messagingSenderId: "696240857696",
    appId: "1:696240857696:web:f7d86d8cef8bd5a56a5905"
  },
  firebaseKey: 'BBOATol76S1Nl9aRph3ZLG155grA22NCOllR0sz0y3IE5TE0H8pjrrYif_h_UM-S8liyIR56oFkaEBui227rfRs',
  firebaseToken: 'defaultFirebaseToken--:APA91bFQpc7jZufdByc5Yo_bxoGo393hRkXu-1IvJQJQ9jCsDX5VYwT7fcyGSEaxst3NKRbEeOjBM-SCMsrjI8KGQsRPziJnGlapViA7_TKNLDfklO-0emRlk7hntwlco3jI68djA9yn'
}