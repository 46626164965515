export default {
  "message": {
    "navbar": {
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова інтерфейсу"])},
      "queues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активні черги"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про проект"])}
    },
    "main": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередження"])},
      "eQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна черга"])},
      "scanQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проскануйте QR код на вході закладу, щоб зайняти чергу"])},
      "connectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стати у чергу"])},
      "completeQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати обслуговування"])},
      "dropQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чергу"])},
      "dropQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якшо ви бажаєте залишити чергу, звільніть будь ласка своє місце у ній."])},
      "skipQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пропустити чергу"])},
      "skipQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якшо ви не встигаєте вчасно прибути до місця, пропустіть одного клієнта вперед себе."])},
      "selectQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати активну чергу"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмовитись"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зрозуміло"])},
      "youNext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви наступний клієнт"])},
      "nowQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша черга"])},
      "nowQueueInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, прямуйте до місця, поки поточний клієнт обслуговується."])},
      "yourPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер обслуговування"])},
      "beforeYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["У черзі перед вами нікого"]), _normalize(["У черзі перед вами одна людина"]), _normalize(["-"]), _normalize(["У черзі перед вами ", _interpolate(_named("n")), " людини"]), _normalize(["У черзі перед вами ", _interpolate(_named("n")), " людей"])])},
      "afterYour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["У черзі за вами нікого"]), _normalize(["У черзі за вами одна людина"]), _normalize(["-"]), _normalize(["У черзі за вами ", _interpolate(_named("n")), " людини"]), _normalize(["У черзі за вами ", _interpolate(_named("n")), " людей"])])},
      "noAfterYour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У черзі за вами нікого"])},
      "averageService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середній час обслуговування"])},
      "averageWait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приблизний час очікування"])},
      "forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогноз початку вашого обслуговування"])},
      "currentService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний клієнт обслуговується"])},
      "statInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розраховано приблизно на підставі даних обслуговування останніх клієнтів."])},
      "userdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заповнити форму"])},
      "userdataInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, заповніть форму, поки знаходитесь у черзі. Це прискорить обслуговування."])},
      "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корисна інформація"])},
      "userInfoInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, ознайомтеся з корисною інформацією, поки знаходитесь у черзі."])},
      "userPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
      "userPayInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, сплатіть ваше замовлення online, поки знаходитесь у черзі. Це прискорить обслуговування."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити всі дані додатку"])},
      "worktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Робочий час сьогодні"])},
      "timebreak": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Без перерв"]), _normalize(["Перерва"]), _normalize(["Перерви"])])},
      "booktime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис у чергу дозволено"])}
    },
    "dialogs": {
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "okQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо, що звернулися до нас!"])},
      "drpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви залишили чергу."])},
      "skpQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви поступилися чергою. Дякуємо."])},
      "cnctQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо, що звернулися до нас! Будь ласка, контролюйте поточний стан черги і розраховуйте свій час, щоб вчасно підійти до місця."])},
      "cnctQueueHead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важлива інформація"])},
      "cnctQueueSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця черга дозволяє <i>поступатися своїм місцем</i> тому, хто за вами, якщо ви не встигаєте прибути вчасно."])},
      "cnctQueuePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця черга дозволяє <i>виконати попередню оплату</i>."])},
      "userdataInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, <i>заповніть форму,</i> поки знаходитесь у черзі. Це прискорить обслуговування."])},
      "cnctQueueDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ваши плани зміняться, будь ласка, залиште чергу, натиснувши відповідну кнопку."])},
      "cnctQueuePush": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви отримаєте сповіщення, коли клієнт перед вами почне обслуговування. Для цього потрібно, щоб додаток чи веб-сторінка, а також ваш браузер мали <i>дозвіл на отримання push-сповіщень.</i> Ми зобов'язуємось не використовувати цю можливість для будь-чого, що не пов'язане з перебуванням у цій черзі."])},
      "apiError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка зв'язку. Будь ласка, спробуйте пізніше."])},
      "impossibleToSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо поступитися чергою."])},
      "impossibleIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз неможливо стати у цю чергу."])},
      "queueNoExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такої черги не існує"])},
      "unknownError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невизначена помилка"])},
      "notInQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вас немає у цій черзі."])},
      "alreadyComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви вже покинули чергу."])},
      "notYourQueue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз не ваша черга."])},
      "dropConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтведіть будь ласка свій намір залишити цю чергу."])},
      "wipeConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтведіть будь ласка свій намір видалити всі дані."])},
      "wipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити дані"])},
      "push": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push-повідомлення"])},
      "pushInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для повноцінної роботи додатку необхідно дозволити отримання push-повідомлень. У разі їх заборони вам доведеться відслідковувати стан черги самостійно."])},
      "reboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезавантаження"])},
      "firebase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація у сервісі push-повідомлень. Зачекайте."])}
    }
  }
}